import { useState } from "react";
import styled from "styled-components";
import { Link } from "react-router-dom";
import { NavLink } from "react-router-dom";

import { scrollToContacts } from "../../pages/Main";
import { VKIcon } from "../../images";


const Wrapper = styled.div`
	display: flex;
	justify-content: start;
	align-items: center;
	height: max-content;
	flex-direction: column;
	bottom: unset;
	top: 0;
	padding: 1em 0;
	.vk{
		position: absolute;
		bottom: 10%;
		width: 3em;
		height: 3em;
		svg{
			width: 100%;
		}
	}
	@media (max-width: 1200px){
		font-size: 2.5em;
		@media(max-width: 768px) {
			font-size: 1em;
			.vk{
				width: 5em;
				height: 5em;
			}
		}
	}
`

const BurgerButton = styled.button`
	z-index: 500;
	position: relative;
	border: 0;
	background-color: transparent;
	display: flex;
	justify-content: center;
	align-items: center;
	.close{
		svg{
			stroke-width: 1px;
			height: 3em;
			width: 3em;
		}
		@media(max-width: 1200px) {
			svg{
				stroke: #fff;
			}
		}
	}
`;

const HeaderNav = styled.nav`
	display: flex;
	justify-content: center;
	align-items: center;
	width: 100%;
	padding: 1em 15%;
	height: max-content;
	margin-top: 40%;
	@media(max-width: 768px) {
		bottom: unset;
		top: 0;
		padding: 1em 0;
	}
	ul{
		display: flex;
		flex-direction: column;
		justify-content: center;
		align-items: center;
		list-style-type: none;
		li{
			margin-top: 1em;
			text-transform: uppercase;
		}	
		@media(max-width: 1200px) {
			font-size: 1.5em;
			@media(max-width: 768px) {
				height: 100%;
				align-items: center;
				font-size: 2em;
				li{
					margin-top: 2em;
				}
				@media(max-width: 375px){
					li{
						margin-top: 1em;
					}
				}
			}
		}
		li{
			a{
				color: #fff;
				&.active{
					color: #41b7c4;
				}
			}
		}
	}
	@keyframes MenuInDesck {
		0% {
			bottom: -2em;
			opacity: 0;
		}
		100% {
			bottom: -3.2em;
			opacity: 1;
		}
	}
	animation-name: MenuInDesck;
	animation-duration: .4s;
`;

const BurgerSvg = () => {
	return (
		<svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 30 30" width="30" height="30" focusable="false">
			<title>Menu</title>
			<path stroke="currentColor" strokeWidth="2" strokeLinecap="round" strokeMiterlimit="10" d="M4 7h22M4 15h22M4 23h22"></path>
		</svg>
)
}

const Close = () => {
	return (
		<div className="close">
			<svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24" fill="none" stroke="currentColor" strokeWidth="2" strokeLinecap="round" strokeLinejoin="round" className="feather feather-x">
				<line x1="18" y1="6" x2="6" y2="18" />
				<line x1="6" y1="6" x2="18" y2="18" />
			</svg>
		</div>
	)
}

export default function HeaderMenuBurger() {

	const [active, setActive] = useState(false);

	const burgerClick = () => {
		setActive(!active)
	}

	const display = active ? 'block' : 'none'
	const position = active ? 'absolute' : 'relative'

	const wrapperStyles = active ? {
		width: '100%',
		height: '100vh',
		backgroundColor: '#005495'
	} : undefined
	
	const buttonStyles = active ? {
		top: '2em',
		right: '2em'
	} : undefined

	const toContacts = () => {
		burgerClick()
		scrollToContacts()
	}

	return (
		<Wrapper style={{...wrapperStyles, position}}>
			<BurgerButton style={{...buttonStyles, position}} onClick={burgerClick}>
				{ display === 'none' ? <BurgerSvg/> : <Close/>}
			</BurgerButton>
			<HeaderNav style={{display}}>
				<ul>
					<li>
						<NavLink className="menu-item" to="/" onClick={burgerClick}>главная</NavLink>
					</li>
					<li>
						<NavLink className="menu-item" to="/dtp" onClick={burgerClick}>дтп</NavLink>
					</li>
					<li>
						<NavLink className="menu-item" to="/poterpevshiye" onClick={burgerClick}>потерпевшие</NavLink>
					</li>
					<li>
						<NavLink className="menu-item" to="/uslugi" onClick={burgerClick}>юридические услуги</NavLink>
					</li>
					<li>
						<Link onClick={toContacts} className="menu-item" to="/" >контакты</Link>
					</li>
				</ul>
			</HeaderNav>
			<a className="link vk" style={{display}} href='https://vk.com/perm_centr_podderzhka' aria-label="Перейти в нашу группу ВКонтакте">
				<VKIcon/>
			</a>
		</Wrapper>
	)
}