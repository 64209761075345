import { useEffect } from "react"
import styled from "styled-components"


const MainWrapper = styled.main`
	/* padding: 7em 5em 0 8em; */
	flex: 1 1 auto;
	h2 {
		font-size: 3em;
	}
`;

export default function Main({ children, style, id = "" }: any) {
	
	useEffect(() => {
		window.scroll({
			left: 0,
			top: 0
		})
	}, [])

	return (
		<MainWrapper style={style} id={id}>
			{children}
		</MainWrapper>
	)
}