import { SyntheticEvent, useEffect } from "react";
import { ParallaxProvider } from "react-scroll-parallax";
import styled from "styled-components";

import { setTitle } from "../..";
import Main from "../../components/main/main";

import MainSection from "./MainSection";
import FourStepsSection from "./FourStepsSection";
import ReviewsSection from "./ReviewsSection";
import PartnerProgramSection from "./PartnerProgramSection";
import ContactsSection from "./ContactsSection";
import GoupSection from "./GoupSection";



const mainStyles = {
	marginTop: '-2em',
	color: 'white',
}

export const Flex = styled.div`
	width: 100%;
	height: 100%;
	display: flex;
	&.column{
		flex-direction: column;
	}
	&.justify{
		&-center{
			justify-content: center;
		}
		&-end{
			justify-content: end;
		}
		&-start{
			justify-content: start;
		}
		&-between{
			justify-content: space-between;
		}
		&-around{
			justify-content: space-around;
		}
		&-evently{
			justify-content: space-evenly;
		}
	}
	&.align{
		&-center{
			align-items: center
		}
		&-end{
			align-items: flex-end
		}
		&-start{
			align-items: flex-start
		}
	}
`;

export const scrollToContacts = (e?: SyntheticEvent) => {
	e?.preventDefault();
	setTimeout(() => {
		const contacts = (document.querySelector('.contacts-block') as HTMLElement)!.offsetTop;
		window.scroll({
			left: 0,
			top: contacts,
			behavior: 'smooth'
		})
	}, 300)
}

export const media = (obj: any, def: any) => {
	for (const el in obj) {
		if (document.body.offsetWidth <= +el) {
			return obj[el]
		}
	}
	return def;
}



const MainPage = () => {

	useEffect(() => {
		setTitle('Пермский Центр Поддержки | Главная')
	}, [])

	return (
		<ParallaxProvider>
			<Main style={mainStyles} id="main-wrapper">

				<MainSection />
			
				<FourStepsSection />

				<ReviewsSection />

				<PartnerProgramSection />

				<ContactsSection />

				<GoupSection />

			</Main>
		</ParallaxProvider>
	)
}

export default MainPage