import styled from "styled-components"


const StripDiv = styled.div`
	width: 100vw;
`;

type Props = {
	padding?: string;
	height?: string;
	color?: string;
	children?: any;
}

export default function Strip({ padding, children, color, height}: Props) {
	return (
		<StripDiv className="strip" style={{
			backgroundColor: color,
			height,
			padding
		}}>
			{children}
		</StripDiv>
	)
}