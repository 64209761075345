import { useEffect } from "react";
import styled from "styled-components";

import { setTitle } from "..";
import Block from "../components/blocks/block";
import Strip from "../components/blocks/strip";
import Main from "../components/main/main";
import Promo from "../components/promo";
import { RubleIcon } from "../images";

import { Flex } from "./Main";



const MainWrapper = styled.div`
	color: rgb(16, 46, 82);
	h3{
		font-size: 1.5em;
	}
	.marked{
		display: flex;
		&::before{
			line-height: .5;
			align-self: flex-start;
			color: #41b7c4;
			content: '■';
			font-size: 1.5em;
			margin-right: 1em;
		}
	}
	.strip{
		padding: 4em 20%;
		@media (max-width: 1200px){
			padding: 4em 12%;
			@media (max-width: 992px) {
				padding: 2em 5%;
			}
		}
	}
	.dtp-list{
		margin: 1em 0 0 1.5em;
		line-height: 1.5;
	}
	.more-pdd{
		padding: 2em;
		@media (max-width: 992px) {
			padding: 0;
		}
	}
	.hardness{
		text-align: center;
	}
	.galochka{
		svg{
			width: 2em;
			height: 1em;
			@media (max-width: 576px){
				width: 6em;
				height: 1em;
			}
		}
		span{
			margin-left: 1em;
		}
	}
	.lined{
		li{
			display: flex;
			flex-direction: column;
			@media (max-width: 450px){
				text-align: center;
			}
			&:not(:last-child){
				margin-bottom: .5em;
				&::after{
					content: '';
					width: 4em;
					color: #fff;
					border-bottom: 1px solid grey;
					margin: .5em 0 0 1em;
					@media (max-width: 450px){
						margin: .5em 0 0 0;
						width: 50%;
						align-self: center;
					}
				}
			}
		}
	}
	.blocks-container{
		@media (max-width: 768px){
			flex-direction: column;
			align-items: center;
		}
		.double-blocks{
			border-radius: 1em;
			display: flex;
			flex-direction: column;
			align-items: center;
			padding: 2em;
			width: 25em;
			font-size: 1.125em;
			font-weight: 500;
			@media (max-width: 768px){
				width: 60%;
				@media (max-width: 576px){
					width: 80%;
					padding: 1em;
					@media (max-width: 450px){
						width: 90%;
					}
				}
			}
			&:last-child{
				margin-left: 4em;
				@media (max-width: 992px){
					margin-left: 2em;
					@media (max-width: 768px){
						margin-left: 0;
						margin-top: 1em;
					}
				}
			}
			svg{
				height: 4em;
			}
		}
	}
`;

export const Paragraph = styled.p`
	font-size: 1.25em;
	@media (max-width: 992px){
		font-size: 1em;
	}
`;

const Galochka = () => {
	return (
		<svg
			preserveAspectRatio="none"
			data-bbox="47.9 51 122.588 98.8"
			viewBox="47.9 51 122.588 98.8"
			height="30"
			width="40"
			xmlns="http://www.w3.org/2000/svg"
			data-type="shape"
			role="presentation"
			aria-hidden="true"
			fill="#5d8f18">
			<g>
				<path d="M169.6 52.2c-1.3-1.3-3.3-1.3-4.6 0l-18.2 18.2V54.2c0-1.8-1.5-3.2-3.2-3.2H51.1c-1.8 0-3.2 1.5-3.2 3.2v92.4c0 1.8 1.5 3.2 3.2 3.2h92.4c1.8 0 3.2-1.5 3.2-3.2v-67l22.8-22.8c1.3-1.3 1.3-3.4.1-4.6zm-29.3 91.1H54.4V57.4h85.9v19.4L100 117.1 81.1 98.4c-1.3-1.3-3.3-1.3-4.6 0s-1.3 3.3 0 4.6l21.1 21.1c.6.6 1.5 1 2.3 1s1.7-.3 2.3-1l38-38 .1 57.2z"></path>
			</g>
		</svg>
	)
};

const Coins = () => {
	return (
		<svg preserveAspectRatio="xMidYMid meet" data-bbox="47.999 62.5 104 74.999" viewBox="47.999 62.5 104 74.999" height="200" width="200" xmlns="http://www.w3.org/2000/svg" data-type="color" role="presentation" aria-hidden="true">
    <g>
        <path fill="#1f5ba5" d="M96.43 94.141c0 5.558-10.616 10.064-23.712 10.064s-23.712-4.506-23.712-10.064 10.616-10.064 23.712-10.064S96.43 88.583 96.43 94.141z" data-color="1"></path>
        <path fill="#1f5ba5" d="M151.576 72.564c0 5.558-10.616 10.064-23.712 10.064s-23.712-4.506-23.712-10.064S114.768 62.5 127.864 62.5s23.712 4.506 23.712 10.064z" data-color="1"></path>
        <path d="M93.711 107.041c-4.424 2.983-12.458 4.836-20.964 4.836-8.982 0-17.227-2.004-21.519-5.232a2.018 2.018 0 0 0-2.825.405 2.026 2.026 0 0 0 .404 2.832c5.03 3.782 13.979 6.04 23.94 6.04 9.406 0 18.085-2.066 23.215-5.525a2.025 2.025 0 0 0 .548-2.807 2.013 2.013 0 0 0-2.799-.549z" fill="#102e52" data-color="2"></path>
        <path d="M93.711 117.829c-4.424 2.983-12.458 4.836-20.964 4.836-8.982 0-17.227-2.004-21.519-5.232a2.017 2.017 0 0 0-2.825.405 2.026 2.026 0 0 0 .404 2.832c5.03 3.782 13.979 6.04 23.94 6.04 9.406 0 18.085-2.066 23.215-5.525a2.025 2.025 0 0 0 .548-2.807 2.013 2.013 0 0 0-2.799-.549z" fill="#102e52" data-color="2"></path>
        <path d="M93.711 128.618c-4.424 2.983-12.458 4.836-20.964 4.836-8.982 0-17.227-2.004-21.519-5.232a2.017 2.017 0 0 0-2.825.405 2.026 2.026 0 0 0 .404 2.832c5.03 3.782 13.979 6.04 23.94 6.04 9.406 0 18.085-2.066 23.215-5.525a2.025 2.025 0 0 0 .548-2.807 2.014 2.014 0 0 0-2.799-.549z" fill="#102e52" data-color="2"></path>
        <path d="M103.953 88.306c5.03 3.782 13.979 6.04 23.94 6.04 9.406 0 18.085-2.066 23.215-5.525a2.025 2.025 0 0 0 .548-2.807 2.013 2.013 0 0 0-2.8-.55c-4.424 2.983-12.458 4.836-20.964 4.836-8.982 0-17.227-2.004-21.519-5.232a2.016 2.016 0 0 0-2.825.405 2.03 2.03 0 0 0 .405 2.833z" fill="#102e52" data-color="2"></path>
        <path d="M148.857 96.253c-4.424 2.983-12.458 4.836-20.964 4.836-8.982 0-17.227-2.004-21.519-5.232a2.016 2.016 0 0 0-2.825.405 2.026 2.026 0 0 0 .404 2.832c5.03 3.782 13.979 6.04 23.94 6.04 9.406 0 18.085-2.066 23.215-5.525a2.025 2.025 0 0 0 .548-2.807 2.013 2.013 0 0 0-2.799-.549z" fill="#102e52" data-color="2"></path>
        <path d="M148.857 107.041c-4.424 2.983-12.458 4.836-20.964 4.836-8.982 0-17.227-2.004-21.519-5.232a2.018 2.018 0 0 0-2.825.405 2.026 2.026 0 0 0 .404 2.832c5.03 3.782 13.979 6.04 23.94 6.04 9.406 0 18.085-2.066 23.215-5.525a2.025 2.025 0 0 0 .548-2.807 2.013 2.013 0 0 0-2.799-.549z" fill="#102e52" data-color="2"></path>
        <path d="M148.857 117.829c-4.424 2.983-12.458 4.836-20.964 4.836-8.982 0-17.227-2.004-21.519-5.232a2.017 2.017 0 0 0-2.825.405 2.026 2.026 0 0 0 .404 2.832c5.03 3.782 13.979 6.04 23.94 6.04 9.406 0 18.085-2.066 23.215-5.525a2.025 2.025 0 0 0 .548-2.807 2.013 2.013 0 0 0-2.799-.549z" fill="#102e52" data-color="2"></path>
        <path d="M148.857 128.618c-4.424 2.983-12.458 4.836-20.964 4.836-8.982 0-17.227-2.004-21.519-5.232a2.017 2.017 0 0 0-2.825.405 2.026 2.026 0 0 0 .404 2.832c5.03 3.782 13.979 6.04 23.94 6.04 9.406 0 18.085-2.066 23.215-5.525a2.025 2.025 0 0 0 .548-2.807 2.014 2.014 0 0 0-2.799-.549z" fill="#102e52" data-color="2"></path>
    </g>
</svg>
	)
}


export const UL = styled.ul`
	&.marker{
		&-off{
			list-style-type: none;
		}
	}
`;

export default function Dtp() {

	useEffect(() => {
		setTitle('Пермский Центр Поддержки | ДТП')
	}, [])

	return (
		<Main>
			<MainWrapper>


				<Strip color="#e2effe">
					<Paragraph>
						<span>Одним из направлений деятельности Центра является помощь в получении компенсационных, страховых и иных выплат лицам, пострадавшим:</span>
						<UL className="dtp-list">
							<li>в результате ДТП</li>
							<li>на общественном транспорте</li>
							<li>на железно-дорожном, водном, воздушном транспорте</li>
							<li>на производстве</li>
							<li>на опасном объекте</li>
							<li>при несчастном случае</li>
							<li>при потере кормильца</li>
						</UL>
					</Paragraph>
				</Strip>

				<Strip color="#f6f6f6">
					<div className="marked">
						<Paragraph>
							Данная сфера деятельности узка и специфична. Не обладая специальными познаниями,
							достаточно сложно разобраться в имеющихся возможностях, формах и способах восстановления
							нарушенных прав, особенно человеку, получившему физическую или моральную травму
						</Paragraph>
					</div>
				</Strip>

				<Strip>
					<h3 className="hardness">Сложности самостоятельной защиты потерпевшим своих прав :</h3>
					<Paragraph>
						<UL className="lined marker-off mar-t-2">
							<li>Минимум информации об имеющихся возможностях</li>
							<li>Бюрократические проволочки</li>
							<li>Наличие физической или моральной травмы</li>
							<li>Отсутствие необходимых знаний</li>
							<li>Удаленность проживания</li>
							<li>Противодействие представителей виновной стороны</li>
							<li>Карантинные ограничения</li>
							<li>Необоснованные отказы уполномоченных на выплату организаций</li>
						</UL>
						<Flex className="galochka mar-t-2">
							<Galochka />
							<span>Поэтому, имеющийся опыт позволяет нам  успешно защищать нарушенные права граждан и добиваться максимальной компенсации причинённого физического и морального вреда</span>
						</Flex>
					</Paragraph>
				</Strip>

				<Strip color="#f6f6f6">
					<h3 className="tex-al-center">СРОКИ</h3>
					<Paragraph className="more-pdd mar-t-2">
						<Flex className="column">
							<span>С момента заключения договора до обращения в уполномоченную организацию в среднем срок составляет от <b><span className="num">2</span> до <span className="num">4</span> недель</b></span>
							<span className="mar-t-1">В отдельных случаях срок может быть увеличен при необходимости проведении дополнительных мероприятий, направленных на:</span>
							<UL className="mar-t-1 mar-l-1_5">
								<li className="mar-t-0_5">установление виновности лица</li>
								<li className="mar-t-0_5">определение степени тяжести вреда здоровью </li>
								<li className="mar-t-0_5">проведение экспертиз (автотехнических, судебно-медицинских,  социальных)</li>
							</UL>
							<span className="mar-t-1_5">Срок принятия решения о выплате с момента обращения в уполномоченную организацию в среднем составляет <b><span className="num">20</span> дней</b></span>
							<span className="mar-t-1"><i>При необоснованном отказе в выплате работа Центра продолжается в претензионном порядке вплоть до обращения в суд</i></span>
						</Flex>
					</Paragraph>
				</Strip>

				<Strip>
					<Flex className="blocks-container justify-center">
						<Block className="double-blocks" color="#f6f6f6">
							<Coins/>
							<Paragraph className="tex-al-center mar-t-1">
								Оплата услуг Центра осуществляется в форме агентского вознаграждения, размер которого согласовывается с клиентом при заключении договора и оплачивается только в случае положительного решения вопроса о выплате
							</Paragraph>
						</Block>
						<Block className="double-blocks" color="#f6f6f6">
							<RubleIcon/>
							<Paragraph className="tex-al-center mar-t-1">
								Клиент не несет абсолютно никаких затрат. Расходы на сбор и оформление документов, а также представительские, транспортные, почтовые, судебные расходы Центр несёт самостоятельно
							</Paragraph>
						</Block>
					</Flex>
				</Strip>

				<Promo>
					Вы можете получить ответ на оставшиеся вопросы, записавшись на <b>бесплатную</b> консультацию
				</Promo>


			</MainWrapper>
		</Main>
	)
}