import styled from "styled-components";
import { useEffect } from "react";

import { setTitle } from "..";
import Main from "../components/main/main";
import PicDesc from "../components/pic-desc";
import Promo from "../components/promo";

import { CarIcon, BankIcon, ShieldIcon, BalanceIcon } from "../images";




const MainWrapper = styled.div`
	color: rgb(16, 46, 82);
	h1{
		text-align: center;
		margin-top: 3em;
		@media (max-width: 992px){
			font-size: 1.5em;
			@media (max-width: 576px){
				margin-top: 1em;
				padding-inline: 5%;
			}
		}
	}
	.uslugi-grid{
		padding: 1em 20%;
		display: grid;
		grid-template: repeat(2, 1fr)/repeat(2, 1fr);
		@media (max-width: 1200px) {
			font-size: .9em;
			padding: 1em 10%;
			@media (max-width: 992px){
				padding: 1em 5%;
				@media (max-width: 576px){
					padding: 0 5% 10% 5%;
					display: flex;
					flex-direction: column;
				}
			}
		}
		&__item{
			padding: 5% 20%;
			@media (max-width: 576px){
				padding: 5% 10%;
			}
			.image-container{
				@media (max-width: 576px){
					height: unset;
				}
				svg{
					height: 6em;
					width: 6em;
				}
			}
			.title{
				font-weight: 500;
				@media (max-width: 576px){
					font-size: 1.2em;
				}
			}
			.description{
				margin-top: 1em;
				color: rgb(93, 93, 93);
			}
			@media (max-width: 576px) {
				&:not(:first-child){
					margin-top: 1em;
					.title{
						margin-top: 1em;
					}
				}
			}
		}
	}
`;


export default function Uslugi() {

	useEffect(() => {
		setTitle('Пермский Центр Поддержки | Услуги')
	}, [])

	return (
		<Main>
			<MainWrapper>
				<h1>Юридические услуги физическим лицам</h1>
				<div className="uslugi-grid">
					<div className="uslugi-grid__item">
						<PicDesc
							icon={<CarIcon/>}
							title='Услуги автоюриста'
							content='Возмещение ущерба при ДТП, оспаривание вины в ДТП, споры со страховыми компаниями по ОСАГО и КАСКО'
						/>
					</div>
					<div className="uslugi-grid__item">
						<PicDesc
							icon={<BankIcon/>}
							title='Страховые и банковские споры'
							content='Споры со страховыми компаниями, не выполнившими обязательства по выплате страхового возмещения, а также с банками и кредитными организациями'
						/>
					</div>
					<div className="uslugi-grid__item">
						<PicDesc
							icon={<ShieldIcon/>}
							title='Взаимодействие с гос.органами'
							content='Представление интересов, подготовка запросов, жалоб, ходатайств, рассмотрение ответов, оспаривание решений'
						/>
					</div>
					<div className="uslugi-grid__item">
						<PicDesc
							icon={<BalanceIcon/>}
							title='Возмещение морального вреда'
							content='Составление исковых заявлений, формирование правовой позиции по делам, представительство в суде'
						/>
					</div>
				</div>
				<Promo>
					Получите профессиональную помощь, записавшись на <b>бесплатную</b> консультацию 
				</Promo>
			</MainWrapper>
		</Main>
	)
}