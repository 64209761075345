import styled from 'styled-components';

import Footer from "./components/footer/footer";
import Header from "./components/header/header";
import AppRouter from "./router/AppRouter";


export const AppWrapper = styled.div`
  min-height: 100%;
  display: flex;
  flex-direction: column;
  width: 100%;
  min-height: 100vh;
  background-color: ${props => props.color || props.theme.color.light};
  font-family: raleway;
  @media(max-width: 1500px){
    font-size: .9em;
    @media(max-width: 1400px){
      font-size: .8em;
      @media(max-width: 1200px){
        font-size: 1em;
      }
    }
  }
  .phone{
    font-family: Arial,Helvetica,sans-serif;
  }
  .mar{
    &-t{
      &-0_5{
        margin-top: .5em;
      }
      &-1{
        margin-top: 1em;
      }
      &-1_5{
        margin-top: 1.5em;
      }
      &-2{
        margin-top: 2em;
      }
    }
    &-l{
      &-0_5{
        margin-left: .5em;
      }
      &-1{
        margin-left: 1em;
      }
      &-1_5{
        margin-left: 1.5em;
      }
      &-2{
        margin-left: 2em;
      }
    }
  }
  .tex-al-center{
    text-align: center;
  }
  .flex{
    display: flex;
    &-column{
      display: flex;
      flex-direction: column;
    }
    &.justify{
      &-center{
        justify-content: center;
      }
      &-end{
        justify-content: end;
      }
      &-start{
        justify-content: start;
      }
      &-between{
        justify-content: space-between;
      }
      &-around{
        justify-content: space-around;
      }
      &-evently{
        justify-content: space-evenly;
      }
    }
    &.align{
      &-center{
        align-items: center
      }
      &-end{
        align-items: flex-end
      }
      &-start{
        align-items: flex-start
      }
    }
  }
  .fsz{
    &-def{
      font-size: 1.25em;
    }
  }
  .num{
    font-family: Arial,Helvetica,sans-serif;
  }
`;

function App(props: any) {
  return (
		<AppWrapper {...props} >
			<Header />
			<AppRouter/>
			<Footer/>
    </AppWrapper>
  );
}

export default App;
