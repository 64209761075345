import { Link } from "react-router-dom";
import styled from "styled-components";

import Strip from "../../components/blocks/strip";
import { ArrowIcon } from "../../images";

import { Flex } from ".";


const Wrapper = styled.div`
	position: relative;
	width: 100vw;
	margin-top: -1px;
	@media(max-width: 1450px) {
		/* font-size: .9em; */
		@media(max-width: 1200px) {
			font-size: .7em;
		}
	}
	.goup-link{
		display: flex;
		flex-direction: column;
		align-items: center;
		color: #fff;
		svg{
			height: 3em;
			width: 3em;
			transform: rotate(180deg)
		}
	}
`;

const scrollUp = () => {
	window.scroll({
		left: 0,
		top: 0,
		behavior: 'smooth'
	})
}


const GoupSection = () => {
	return (
		<Wrapper className="goup">
			<Strip color="rgb(0, 48, 91)" height="6em">
				<Flex className="justify-center">
					<Link className="goup-link" onClick={scrollUp} to="/">
						<ArrowIcon/>
						<span>НАВЕРХ</span>
					</Link>
				</Flex>
			</Strip>
		</Wrapper>
	)
}

export default GoupSection