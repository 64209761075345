import styled from "styled-components";

import { ArrowDoubleIcon } from "../../images";
import Strip from "../../components/blocks/strip";

import { Flex, media, scrollToContacts } from ".";



const Wrapper = styled.div`
	position: relative;
	width: 100vw;
	@media(max-width: 1450px) {
		/* font-size: .9em; */
		@media(max-width: 1200px) {
			font-size: .7em;
			@media(max-width: 567px) {
				.partner{
					h2{
						font-size: 2.5em;
						margin-top: 1em;
					}
				}
			}
		}
	}
	.partner{
		padding: 6% 20% 5% 20%;
		@media(max-width: 1200px){
			padding: 6% 5% 5% 5%;
		}
		h2, p{
			color: #00305B;
			text-align: center;
		}
		p{
			@media(max-width: 567px) {
				text-align: left;
			}
			&:nth-child(2){
				margin-top: 4em;
				@media(max-width: 567px) {
					margin-top: 2em;
				}
			}
			&:nth-child(4){
				margin-top: 4em;
				font-size: 1.125em;
				@media(max-width: 567px) {
					margin-top: 2em;
					font-size: 1.25em;
				}
			}
			font-size: 1.25em;
			margin-top: 2em;
		}
		a{
			margin-top: 2em;
		}
	}
	.gis-links{
		height: unset;
		position: absolute;
		top: -1.5em;
		justify-content: center;
		@media(max-width: 567px) {
			top: -2.5em;
			font-size: 1.1em;
			@media(max-width: 450px) {
				justify-content: space-around;
			}
		}
		a:first-child{
			margin-right: 3em;
			@media(max-width: 450px) {
				margin-right: 0;
			}
		}
	}
`;

const ReviewButton = styled.a`
	background-color: #fff;
	padding: 1em 2em;
	color: #51949b;
	font-family: 'Helvetica';
	border-radius: 1em;
	border-top: 4px solid #51949b;
	transition: .05s;
	height: max-content;
	&:hover{
		color: #41b7c4;
		border-top: 8px solid #41b7c4;
	}
`;

const AnimatedLink = styled.a`
	color: black;
	@keyframes Jumping {
		0% {
			margin-top: 2em;
		}
		33% {
			margin-top: 2.5em
		}
		66% {
			margin-top: 2.5em
		}
		100% {
			margin-top: 2em
		}
	}
	animation-name: Jumping;
	animation-duration: .7s;
	animation-iteration-count: infinite;
`;

const gis_review_post = 'https://2gis.ru/perm/inside/2252435468867585/firm/70000001044245620/tab/reviews?writeReview&m=56.24455%2C58.005856%2F17.75';
const gis_review_get = 'https://2gis.ru/perm/inside/2252435468867585/firm/70000001044245620/tab/reviews?m=56.24455%2C58.005856%2F17.75';


const PartnerProgramSection = () => {
	return (
		<Wrapper className="partner">
			<Strip height={media({1080: '36em', 800 : '45em', 360: '48em'}, '40em')} color="rgb(234, 244, 246)">
				<Flex className="gis-links">
					<ReviewButton href={gis_review_post}>ОСТАВИТЬ ОТЗЫВ</ReviewButton>
					<ReviewButton href={gis_review_get}>СМОТРЕТЬ В 2ГИС</ReviewButton>
				</Flex>
				<Flex className="partner column align-center">
					<h2><b>ПАРТНЁРСКАЯ</b> ПРОГРАММА</h2>
					<p>
						Важным направлением работы Центра является развитие партнерской программы, предусматривающей
						сотрудничество с физическими лицами и организациями, способными привлечь к заключению договора
						клиентов, нуждающихся в получении юридической помощи по направлениям деятельности Центра.
					</p>
					<p>
						Партнерской программой предусмотрено сотрудничество на длительной
						основе, условия которого согласовываются индивидуально, либо разовая
						оплата за каждого привлеченного клиента.
					</p>
					<p className="small">
						Для получения более подробной информации об имеющихся вариантах
						сотрудничества свяжитесь с нами
					</p>
					<AnimatedLink onClick={(e) => scrollToContacts(e)} href="/" aria-label="Скролл до раздела с контактами">
						<ArrowDoubleIcon/>
					</AnimatedLink>
				</Flex>
			</Strip>
		</Wrapper>
	)
}

export default PartnerProgramSection