import styled from "styled-components";
import { Link } from "react-router-dom";
import { NavLink } from "react-router-dom";

import { scrollToContacts } from "../../pages/Main";


const HeaderNav = styled.nav`
	width: 40%;
	@media(max-width: 1450px) {
		width: 50%;
	}
	ul{
		display: flex;
		list-style-type: none;
		justify-content: space-between;
		li{
			a.menu-item{
				position: relative;
				color: rgb(15,76,133);
				text-decoration: none;
				text-transform: uppercase;
				transition: color .2s;
				&::after{
						content: '';
						position: absolute;
						height: 1px;
						left: 0;
   					bottom: 0;
						width: 0;
						border-bottom: 3px solid rgb(65,183,196);
					}
				&:hover{
					color: rgb(65,183,196);
				}
				&.active{
					&::after{
						content: '';
						position: absolute;
						height: 1px;
						left: 0;
   					bottom: -.3em;
						width: 100%;
						border-bottom: 3px solid rgb(65,183,196);
						transition: width .2s;
					}
				}
			}
		}
	}
`;

export default function HeaderMenu() {
	return (
		<HeaderNav>
			<ul>
				<li>
					<NavLink className="menu-item" to="/">главная</NavLink>
				</li>
				<li>
					<NavLink className="menu-item" to="/dtp">дтп</NavLink>
				</li>
				<li>
					<NavLink className="menu-item" to="/poterpevshiye">потерпевшие</NavLink>
				</li>
				<li>
					<NavLink className="menu-item" to="/uslugi">юридические услуги</NavLink>
				</li>
				<li>
					<Link onClick={() => scrollToContacts()} className="menu-item" to="/">контакты</Link>
				</li>
			</ul>
		</HeaderNav>
	)
}