import styled from "styled-components";


const PhoneA = styled.a`
	color: #00305b;
`;

export default function Phone({children}:any) {
	return (
		<PhoneA className="phone" href={`tel:${children}`}>
			{children}
		</PhoneA>
	)
}