import styled from "styled-components";

import Phone from "../phone";

import LogoAnchor from "./header-logo";
import HeaderMenu from "./header-menu";
import HeaderMenuBurger from "./header-menu-burger";


const HeaderWrapper = styled.header`
	z-index: 100;
	position: sticky;
	top: 0;
	width: 100%;
	height: 6em;
	background-color: #fff;
	box-shadow: 0 2px 10px -2px gray;
	display: flex;
	justify-content: space-around;
	align-items: center;
	@media (max-width: 1200px) {
		font-size: .7em;
		.phone{
			font-size: 1.5em;
		}
	}
`;

const getBodyWidth = () => {
	return document.body.offsetWidth
}

export default function Header() {
	return (
		<HeaderWrapper>
			<LogoAnchor/>
			{ getBodyWidth() > 850 && <HeaderMenu />}
			<Phone>8-800-20-17-596</Phone>
			{ getBodyWidth() <= 850 && <HeaderMenuBurger />}
		</HeaderWrapper>
	)
}