import { Link } from "react-router-dom";
import styled from "styled-components";

import { Logo } from "../../images";



const LogoA = styled.div`
	a{
		display:flex;
		align-items: center;
		svg.logo-icon{
			width: 5.25em;
			height: 5.25em;
			background-color: rgb(0 86 148);
			color: white;
			padding: 0.75em;
		}
		span{
			margin-left: 1em;
			font-size: 1.3em;
			font-family: 'Noticia Text', serif;
			color: #00305b;
		}
		@media (max-width: 768px) {
			span{
				display: none;
			}
		}
	}
`;

export default function LogoAnchor() {
	return (
		<LogoA>
			<Link to='/' aria-label="Отправиться на главную страницу">
				<Logo className="logo-icon"/>
				<span>
					ПЕРМСКИЙ<br/>
					ЦЕНТР<br/>
					ПОДДЕРЖКИ
				</span>
			</Link>
		</LogoA>
	)
}