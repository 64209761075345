import { Link } from "react-router-dom";
import styled from "styled-components"

import { Flex, scrollToContacts } from "../pages/Main";



const PropmoDiv = styled.div`
	background-color: #ddecf8;
	padding: 4em 20%;
	@media (max-width: 992px) {
		font-size: .8em;
		padding: 4em 10%;
		@media (max-width: 576px){
			padding: 2em 5%;
		}
	}
	.text{
		text-align: center;
		font-size: 1.5em;
	}
	a{
		background-color: #4d7ba7;
		transition: .2s;
		color: #fff;
		padding: .75em 1.5em;
		border-radius: .25em;
		&:hover{
			background-color: #658eb4;
		}
	}
`;

export default function Promo({children}: any) {
	return (
		<PropmoDiv>
			<Flex className="column align-center">
				<span className="text">
					{children}
				</span>
				<Link onClick={() => scrollToContacts()} className="mar-t-2" to='/'>Записаться</Link>
			</Flex>
		</PropmoDiv>
	)
}