import { ParallaxBanner, ParallaxBannerLayer } from "react-scroll-parallax"
import styled from "styled-components"

import { MapIcon, VKIcon, phone } from "../../images"
import Block from "../../components/blocks/block"
import Strip from "../../components/blocks/strip"
import ApplyForm from "../../components/form"
import Phone from "../../components/phone"

import { Flex, media } from "."


const Wrapper = styled.div`
	#contacts-id{
		.title{
			padding: 6em 3em 0 0;
		}
		h3{
			font-size: 1.25em;
		}
		.contacts{
			width: 60%;
			padding-inline: 3em;
			&-info{
				&__adress{
					margin-top: 4em;
					&_item{
						.mm-link{
							display: flex;
							align-items: center;
							color: #41b7c4;
							span{
								margin-left: .5em;
							}
							&.vk{
								margin-left: 2em;
							}
						}
						&:not(:first-child){
						margin-top: 1em;
						.phones{
							margin-left: 1em;
							.phone{
								display: flex;
								a{
									margin-right: 1em;
									color: #fff;
								}
								&:not(:first-child){
									margin-top: .5em;
								}
							}
						}
					}
					}
				}
			}
			&-form{
				margin-top: 4em;
				padding-right: 20%;
			}
		}
	}
	@media(max-width: 1450px) {
		/* font-size: .9em; */
		@media(max-width: 1200px) {
			font-size: .7em;
			#contacts-id{
				justify-content: unset;
			}
			@media(max-width: 875px) {
				#contacts-id{
					flex-direction: column;
					.title{
						padding: 2em;
						justify-content: center;
						h2{
							font-size: 2.5em;
							text-align: center;
						}
					}
					.contacts{
						width: 90%;
						margin-top: 2em;
						padding-inline: 1em;
						&-info{
							font-size: 1.5em;
							&__adress{
								margin-top: 2em;
							}
							h3{
								text-align: center;
							}
						}
						&-form{
							margin-top: 1em;
							padding-right: 0;
							font-size: 1.5em;
						}
					}
				}
				@media(max-width: 475px){
					.phones{
						.phone{
							flex-direction: column
						}
					}
				}
			}
		}
	}
`

const gis_location = 'https://2gis.ru/perm/inside/2252435468867585/firm/70000001044245620?m=56.244511%2C58.00575%2F17.75';


const ContactsSection = () => {
	return (
		<Wrapper className='contacts-block'>
			<ParallaxBanner style={{height: media({1080: '82em', 875 : '87em'}, '48em')}}>
				<ParallaxBannerLayer image={phone} translateY={media({ 575: [0, 0] }, [0, 70])} speed={0} />
				<ParallaxBannerLayer speed={0}>
					<Strip height="100%" color="rgb(0 48 91 / 70%)">
						<Flex id='contacts-id' className="align-center">
							<Block height={media({'875' : 'max-content'}, '100%')} width={media({'875' : '100%'}, '40%')} color="rgb(0 48 91 / 66%)">
								<Flex className="title justify-end">
									<h2>
										<b>СВЯЖИТЕСЬ</b><br />
										С НАМИ
									</h2>
								</Flex>
							</Block>
							<div className="contacts">
								<div className="contacts-info">
									<h3><b>НАШ</b> АДРЕС</h3>
									<div className="contacts-info__adress">
										<p className="contacts-info__adress_item"><span className="num">614039</span>, г. Пермь, Комсомольский проспект, д. <span className="num">37</span>, оф. <span className="num">32</span>, <span className="num">3</span> этаж<br/>(Пермский крайсовпроф)</p>
										<p className="contacts-info__adress_item">Email: permcentrdtp@gmail.com</p>
										<Flex className="contacts-info__adress_item">
											<span>Тел:</span>
											<Flex className="column phones">
												<div className="phone">
													<Phone>8-800-20-17-596</Phone>
													<span>звонок по РФ бесплатный</span>
												</div>
												<div className="phone">
													<Phone>8-992-22-66-596</Phone>
													<span>юридический отдел</span>
												</div>
											</Flex>
										</Flex>
										<Flex className="contacts-info__adress_item">
											<a className="mm-link" href={gis_location} aria-label="Посмотреть нас на карте">
												<MapIcon/>
												<span>Смотреть на карте</span>
											</a>
											<a className="mm-link vk" href='https://vk.com/perm_centr_podderzhka' aria-label="Перейти в нашу группу ВКонтакте">
												<VKIcon/>
											</a>
										</Flex>
									</div>
								</div>
								<div className="contacts-form">
									<ApplyForm/>
								</div>
							</div>
						</Flex>
					</Strip>
				</ParallaxBannerLayer>
			</ParallaxBanner>
		</Wrapper>
	)
}

export default ContactsSection