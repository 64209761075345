import styled from "styled-components";
import { ParallaxBanner, ParallaxBannerLayer } from "react-scroll-parallax"

import { ConsultantIcon, DogovorIcon, RealizationIcon, IssledovaniyeIcon, img_person } from "../../images";
import Strip from "../../components/blocks/strip";
import PicDesc from "../../components/pic-desc";

import { Flex, media } from ".";



const Wrapper = styled.div`
	@media(max-width: 1200px) {
		font-size: .7em;
	}
	.success{
		padding: 5%;
		h2{
			@media(max-width: 1080px){
				text-align: center;
				@media(max-width: 450px){
					font-size: 2.5em;
				}
			}
		}
		h3{
			margin-top: 2em;
			font-size: 1.25em;
			color: #DBDBDB;
			@media(max-width: 1080px){
				font-size: 1.5em;
			}
		}
		.four-steps{
			margin-top: 5em;
			height: max-content;
			@media(max-width: 1080px){
				display: grid;
    		grid-template: 1fr 1fr/1fr 1fr;
				.pic-desc{
					&:not(:first-child, :nth-child(2)){
						margin-top: 2em;
					}
					.title{
						font-size: 1.5em;
					}
					.description{
						font-size: 1.5em;
					}
				}
				@media(max-width: 575px){
					display: flex;
					flex-direction: column;
					.pic-desc{
						&:not(:first-child){
							margin-top: 2em;
						}
					}
				}
			}
			svg{
				height: 5em;
				width: 5em;
			}
			.title{
				font-size: 1.25em;
				font-weight: 700;
				margin-top: 1em;
			}
			.description{
				margin-top: 1em;
				color: #DBDBDB;
			}
		}
	}
`

const FourStepsSection = () => {
	return (
		<Wrapper>
			<ParallaxBanner style={{height: media({1080: '720px', 575: '110em'}, '640px')}} className='aspect-[2/1]'>
				<ParallaxBannerLayer image={img_person} translateY={media({ 575: [0, 0] }, [0, 70])} speed={0} />
				<ParallaxBannerLayer speed={0} expanded={false}>
					<Strip height='100%' color="rgb(15 76 133 / 70%)">
						<Flex className="success column align-center justify-center" >
							<h2><span className="num">4</span> ШАГА УСПЕШНОГО СОТРУДНИЧЕСТВА</h2>
							<h3>Этапы работы</h3>
							<Flex className="four-steps justify-evently">
								<PicDesc icon={<ConsultantIcon/>} title='Консультация' content={<>Личная встреча в офисе либо<br/> дистанционное общение</>}/>
								<PicDesc icon={<DogovorIcon/>} title='Договор' content={<>Согласование условий<br/> и подписание договора</>}/>
								<PicDesc icon={<IssledovaniyeIcon/>} title='Исследование' content={<>Формирование правовой<br/> позиции по делу</>}/>
								<PicDesc icon={<RealizationIcon/>} title='Реализация' content={<>Обращение в уполномоченную<br/> организацию/суд</>}/>
							</Flex>
						</Flex>
					</Strip>
				</ParallaxBannerLayer>
			</ParallaxBanner>
		</Wrapper>
	)
}

export default FourStepsSection