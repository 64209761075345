import { useRef, useState, FormEvent } from "react";
import { sendForm } from "@emailjs/browser"
import styled from "styled-components";
import ReCAPTCHA from "react-google-recaptcha";

import { Flex } from "../pages/Main";
import { LoaderIcon } from "../images";

const Form = styled.form`
	display: flex;
	flex-direction: column;
	span{
		margin-bottom: 1em;
	}
	.inputs{
		width: 50%;
	}
	.submit{
		position: relative;
		margin-top: .5em;
		align-items: center;
		justify-content: space-between;
		.loader{
			align-items: center;
			width: max-content;
			&__icon{
				height: 1.2em;
				animation: spin .7s steps(8, end) 0s infinite;
			}
		}
		.status{
			padding: 0.5em;
			border-radius: 0.25em;
			&.error{
				background-color: #d34949;
			}
			&.ok{
				border: 1px solid #57c466;
				color: #57c466;
			}
		}
		.captcha-block{
			position: absolute;
			top: 0;
			right: 0;
		}
	}
	input{
		height: 2.5em;
		&:not(:first-child){
			margin-top: .5em;
		}
		&[type=submit]{
			cursor: pointer;
			align-self: end;
			font-family: raleway;
			font-weight: 500;
			background-color: #41b7c4;
			color: #fff;
			font-size: 1em;
			transition: .2s;
			&:hover{
				background-color: #3ba5b1;
			}
		}
	}
	input, textarea{
		padding: .5em;
		border: 0;
		border-radius: .25em;
		@media(max-width: 875px){
			font-size: 1.2em;
		}
		::placeholder{
			font-family: raleway;
			font-weight: 500;
		}
	}
	textarea{
		font-family: raleway;
		width: 50%;
		margin-left: .5em;
		resize: none;
	}
	@media(max-width: 875px){
		.areas{
			flex-direction: column;
			.inputs{
				width: 100%;
			}
			textarea{
				width: 100%;
				margin-left: 0;
				margin-top: .5em;
				height: 10em;
			}
		}
		input[type=submit]{
			align-self: unset;
			margin-bottom: 0;
		}
		.status{
			font-size: .8em;
		}
	}
	@keyframes spin {
		from {
			transform: rotate(0deg);
		}

		to {
			transform: rotate(360deg);
		}
	}
`;

const siteKey = '6Lc5tc0lAAAAAIo2S2PVdN-EVsves76EOfqf6w9E'

export default function ApplyForm() {

	const [isError, setIsError] = useState(false)
	const [isOk, setIsOk] = useState(false)
	const [isLoading, setIsLoading] = useState(false)
	const [recaptchaShow, setRecaptchaShow] = useState(false);

	const form = useRef<HTMLFormElement>(null)

  const handleRecaptchaChange = async (token: string | null) => {
		const formCurrent = form.current

		if (formCurrent && token) {
			setTimeout(() => setRecaptchaShow(false), 1000) 
			setIsLoading(true)
			try {
				const result = await sendForm('service_pajnwtl', 'template_znira53', formCurrent, 'FwsXJPjhtnZ4FEh14')
				setIsLoading(false)
				if (result.status === 200) {
					setIsOk(true)
					setTimeout(() => setIsOk(false), 5000)
				}
			} catch (err) {
				setIsLoading(false)
				setIsError(true)
				setTimeout(() => setIsError(false), 5000)
			}
		}
  };

	
	const handleSubmit = (e: FormEvent<HTMLFormElement>) => {
		e.preventDefault();
		setRecaptchaShow(true)
	}

	return (
		<Form ref={form} onSubmit={handleSubmit}>
			<span>Напишите нам, и мы свяжимся с вами в самое ближайшее время:</span>
			<Flex className="areas">
				<Flex className="inputs column">
					<input type="text" name="user_name" placeholder="Имя" required/>
					<input type="email" name="user_email" placeholder="Эл. почта" required/>
					<input type="tel" name="user_phone" placeholder="Телефон" required/>
				</Flex>
				<textarea name="user_message" placeholder="Добавьте сообщение" />
			</Flex>
			<Flex className="submit">
				<input type="submit" value="Отправить" />	
				{isLoading && <Flex className="loader"><div>Отправка</div><LoaderIcon className="loader__icon"/></Flex>}
				{recaptchaShow && <div className="captcha-block"><ReCAPTCHA sitekey={siteKey} onChange={handleRecaptchaChange} /></div>}
				{isError && <div className="status error">Ошибка при отправке формы</div>}
				{isOk && <div className="status ok">Сообщение отправлено!</div>}
			</Flex>
		</Form>
	)
}