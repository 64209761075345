import { ParallaxBanner, ParallaxBannerLayer } from "react-scroll-parallax"
import { Link } from "react-router-dom"
import styled from "styled-components"

import Block from "../../components/blocks/block"
import Strip from "../../components/blocks/strip"

import { dom_prof, dom_prof_tablet } from "../../images"

import { Flex, media } from "."


const Wrapper = styled.div`
	@media(max-width: 1200px) {
		font-size: .7em;
		@media(max-width: 1080px) {
			font-size: .6em;
			.main-section-flex{
				flex-direction: column;
				justify-content: center;
				align-items: center;
				&__block{
					display: flex;
					align-items: center;
					justify-content: center;
				}
				.right-align{
					justify-content: center;
					padding: 4em 2em;;
					.company-services{
						font-size: 1.2em;	
					}
				}
			}
		}
	}
	
	.right-align{
		padding: 7em 2em 0 0;
		display: flex;
		justify-content: end;
		.main-info{
			font-size: 2em;
			display: flex;
			flex-direction: column;
			.company-services{
				@media(max-height: 800px){
					margin-top: 2em;
				}
				margin-top: 3em;
				padding-left: .7em;
				&__item{
					margin-left: 1em;
				}
				li{
					::marker{
						color: #41b7c4;
						content: '■';
						font-size: .7em;
					}
					&:not(:first-child){
						@media(max-height: 800px){
							margin-top: 1em;
						}
						margin-top: 2em;
					}
					.label{
						font-size: .8em;
					}
					a{
						color: #41b7c4;
						font-size: .6em;
					}
				}
			}
		}
	}
`

const TitlePhrase = styled.div`
	width: 60%;
	display: flex;
	justify-content: center;
	align-items: center;
	font-size: 1.7em;
	@media(max-width: 1080px){
		display: none;
	}
`;


const MainSection = () => {

	return (
		<Wrapper className='main-section'>
			<ParallaxBanner style={{height: '100vh', marginTop: '-2em', backgroundImage: 'linear-gradient(90deg, rgb(0, 48, 91), white)'}} className='aspect-[2/1]'>
				<ParallaxBannerLayer image={media({1200 : dom_prof_tablet}, dom_prof)} speed={10} translateY={media({575: [5, 5]}, [5, 50])} shouldAlwaysCompleteAnimation={true} expanded={false}/>
				<ParallaxBannerLayer speed={0} expanded={false}>
					<Strip height="100%" color="rgb(0 48 91 / 50%)">
							<Flex className="main-section-flex align-center">
								<Block className="main-section-flex__block" height={media({'1080' : 'max-content'}, '100%')} width={media({'1080' : '80%'}, '40%')} color="rgb(0 48 91 / 66%)">
									<div className="right-align">
										<div className="main-info">
											<h1 className="company-name">
												ПЕРМСКИЙ<br/>
												ЦЕНТР <br/>
												ПОДДЕРЖКИ
											</h1>
											<ul className="company-services">
												<li>
													<div className="company-services__item">
														<div className="label">ПОСТРАДАВШИХ В ДТП</div>
														<Link to='/dtp'>Подробнее</Link>
													</div>
												</li>
												<li>
													<div className="company-services__item">
														<div className="label">ПОТЕРПЕВШИХ ОТ<br/> ПРЕСТУПЛЕНИЙ</div>
														<Link to='/poterpevshiye'>Подробнее</Link>
													</div>
												</li>
												<li>
													<div className="company-services__item">
														<div className="label">ЮРИДИЧЕСКИЕ УСЛУГИ</div>
														<Link to='/uslugi'>Подробнее</Link>
													</div>
												</li>
											</ul>
										</div>
									</div>
								</Block>
								<TitlePhrase>
									<p>
										Работа Центра состоит в защите прав и законных<br/>
										интересов лиц, оказавшихся в сложной<br/>
										жизненной ситуации, и направлена на<br/>
										поддержку и восстановление эмоциональных,<br/>
										физических и материальных благ
									</p>
								</TitlePhrase>
							</Flex>
						</Strip>
				</ParallaxBannerLayer>
			</ParallaxBanner>
		</Wrapper>
	)
}

export default MainSection