import { ReactNode } from "react";
import styled from "styled-components";


const Div = styled.div`
	display: flex;
	flex-direction: column;
	align-items: center;
	.image-container{
		background-color: #fff;
		border-radius: 50%;
		width: 10em;
		height: 10em;
		display: flex;
		justify-content: center;
		align-items: center;
	}
	.title, .description{
		text-align: center;
	}
`;


interface PicDescProps {
	icon: ReactNode
	title: string
	content: JSX.Element | string
}


export default function PicDesc({icon, title, content}: PicDescProps) {
	return (
		<Div className="pic-desc">
			<div className="image-container">
				{icon}
			</div> 
			<div className="title">{title}</div>
			<div className="description">{content}</div> 
		</Div>
	)
}