import styled from "styled-components";
import { useRef } from "react"

import { alina, andrey, ArrowScrollIcon, julia, KavichkaIcon } from "../../images"
import Strip from "../../components/blocks/strip"

import { Flex, media } from "."


const Wrapper = styled.div`
	position: relative;
	width: 100vw;
	@media(max-width: 1450px) {
		/* font-size: .9em; */
		@media(max-width: 1200px) {
			font-size: .7em;
		}
	}
	.reviews-body{
		padding: 5%;
		@media(max-width: 450px){
			padding: 10% 5% 5% 5%;
		}
		.reviewers{
			width: 100vw;
			margin-top: 3em;
			@media(max-width: 1080px) {
				overflow-x: hidden;
				.reviewers-wrapper{
					width: max-content;
					.reviewer{
						font-size: 1.3em;
						width: 100vw;
						@media(max-width: 450px){
							font-size: 1.1em;
						}
					}
				}
			}
		}
		.arrows-scroll{
			display: flex;
			justify-content: space-between;
			width: 30%;
			&__right svg{
				transform: rotate(180deg);
			}
			@media(min-width: 1080px){
				display: none;
			}
			@media(max-width: 1080px){
				margin-top: 1em;
				@media(max-width: 450px){
					margin-top: .5em;
				}
			}
			button{
				border: none;
				background-color: transparent;
				svg{
					stroke: #00305B;
				}
			}
		}
	}
	.scale{
		transform: scaleY(2);
	}
	h2, p{
		color: #00305B;
		@media(max-width: 450px){
			font-size: 2.5em;
		}
	}
	p{
		font-size: 1.25em;
	}
	.kav{
		height: 2em;
		width: 2em;
		margin-right: 2em;
	}
	.citate{
		margin-top: 2em;
	}
	.client{
		&-name{
			font-size: 1.25em;
			font-weight: 500;
			color: #5c5c5c;
			margin-left: 1em;
		}
		&-info{
			height: auto;
			img{
				border-radius: 50%;
				height: 5em;
				width: 5em;
			}
		}
	}

`


const ReviewsSection = () => {

	const rs = useRef<HTMLDivElement>(null)

	const xScroll = (param: string) => {
		const rsCurr = rs.current
		rsCurr && rsCurr.scrollBy({
			left: param === 'right' ? rsCurr.clientWidth : -rsCurr.clientWidth,
			top: 0,
			behavior: 'smooth'
		})
	}

	return (
		<Wrapper className="reviews">
			<Strip height={media({'1080' : '47em', '400' : '40em'}, '40em')} color="#fff">
				<Flex className="reviews-body column align-center">
					<h2>
						<b>ОТЗЫВЫ</b> КЛИЕНТОВ
					</h2>
					<div ref={rs} className="reviewers">
						<Flex className="reviewers-wrapper justify-evently">
							<Reviewer
								avatar={andrey}
								name='Андрей Минин'
								text={
									<>
										Выражаем свою Благодарность<br />
										Пермскому центру поддержки<br />
										пострадавших в ДТП.<br />
										Рекомендуем на все 100%.<br />
										Команда высококачественных<br />
										специалистов, помогли<br />
										получить все выплаты по<br />
										максимуму и в короткие сроки.<br />
										Спасибо.
									</>
								}
							/>
							<Reviewer
								avatar={alina}
								name='Алина Мугдасимова'
								text={
									<>
										Хотим выразить благодарность<br />
										Центру помощи пострадавшим<br />
										в ДТП и лично Дмитрию за<br />
										оказанную помощь. Без помощи<br />
										центра мы бы не смогли<br />
										добиться положительных<br />
										результатов за такой короткий<br />
										срок. Низкий поклон вашей<br />
										команде за понимание и<br />
										сочувствие к чужой беде.
									</>
								}
							/>
							<Reviewer
								avatar={julia}
								name='​Юлия Двоеглазова'
								text={
									<>
										Хочу выразить огромную<br />
										благодарность пермскому центру<br />
										поддержки пострадавшим в<br />
										дтп.Помогли моему брату(Двоеглазову<br />
										Олегу) получить выплаты по травмам<br />
										за короткое время,все быстро и без<br />
										проволочек.Спасибо вашей<br />
										команде,благодаря вашей помощи он<br />
										сможет продолжить лечение и<br />
										реабилитацию в дальнейшем.
									</>
								}
							/>
						</Flex>
					</div>
					<div className="arrows-scroll" >
						<button className="arrows-scroll__left" onClick={() => xScroll('left')} aria-label="Предыдущий отзыв">
							<ArrowScrollIcon/>
						</button>
						<button className="arrows-scroll__right" onClick={() => xScroll('right')} aria-label="Следующий отзыв">
							<ArrowScrollIcon/>
						</button>
					</div>
				</Flex>
			</Strip>
		</Wrapper>
	)
}


interface ReviewerProps{
	avatar: string
	name: string
	text: JSX.Element
}

const Reviewer = ({avatar, name, text}: ReviewerProps) => (
	<Flex className="reviewer column">
		<Flex className="align-center justify-center client-info">
			<img height='5em' width='5em' src={avatar} alt={avatar} />
			<span className="client-name">{name}</span>
		</Flex>
		<Flex className="justify-center citate">
			<KavichkaIcon className="kav"/>
			<p>
				{text}
			</p>
		</Flex>
	</Flex>
)

export default ReviewsSection