import { useEffect } from "react";
import styled from "styled-components";

import { setTitle } from "..";
import Strip from "../components/blocks/strip";
import Main from "../components/main/main";
import Promo from "../components/promo";

import { Paragraph, UL } from "./dtp";


const MainWrapper = styled.div`
	color: rgb(16, 46, 82);
	.strip{
		padding: 4em 20%;
		@media (max-width: 1200px){
			padding: 4em 12%;
			@media (max-width: 992px) {
				padding: 2em 5%;
			}
		}
	}
	.frst{
		@media (max-width: 992px) {
			font-size: 1em;
		}
	}
	.defends{
		li{
			line-height: 1.5;
		}
	}
	.shield{
		&-left{
			width: 60%;
		}
		svg{
			width: 5em;
			height: 5em;
		}
	}
	.fincult{
		font-size: 1.5em;
		a{
			text-decoration: underline;
			color: rgb(16, 46, 82);
		}
	}
`;

const Shield = () => {
	return (
		<svg preserveAspectRatio="xMidYMid meet" data-bbox="-0.005 0 47.009 58" viewBox="-0.005 0 47.009 58" height="58" width="47" xmlns="http://www.w3.org/2000/svg" data-type="color" role="presentation" aria-hidden="true">
    <g>
        <path fill="#DBDBDB" d="M35 27.5C35 33.851 29.851 39 23.5 39S12 33.851 12 27.5 17.149 16 23.5 16 35 21.149 35 27.5z" data-color="1"></path>
        <path fill="#83B7E6" d="M47 10.91l-1.28 22.01a20.764 20.764 0 0 1-2.43 8.68 20.289 20.289 0 0 1-5.93 6.76l-13.41 9.5a.793.793 0 0 1-.9 0l-13.41-9.5a20.289 20.289 0 0 1-5.93-6.76 20.767 20.767 0 0 1-2.43-8.69L0 10.91a3.379 3.379 0 0 1 2.65-3.48 13.056 13.056 0 0 1 2.42-.18h.55a20.025 20.025 0 0 0 12.07-3.74l4.22-3a2.768 2.768 0 0 1 1.6-.51 2.69 2.69 0 0 1 1.59.51l4.34 3.06a20.218 20.218 0 0 0 11.75 3.71c.15 0 .3 0 .45-.01h.51a11.855 11.855 0 0 1 2.19.17A3.383 3.383 0 0 1 47 10.91zm-2.97-1.42a10.22 10.22 0 0 0-1.88-.14c-.16 0-.32 0-.48.01h-.48a21.8 21.8 0 0 1-12.64-3.99L24.21 2.3a1.26 1.26 0 0 0-.7-.22 1.2 1.2 0 0 0-.71.23L18.59 5.3A21.593 21.593 0 0 1 6.04 9.34c-.15 0-.3 0-.45-.01h-.52a11.593 11.593 0 0 0-2.11.15c-.86.18-.95.96-.9 1.85l1.28 21.5a17.176 17.176 0 0 0 7.2 13.74l12.96 9.18 12.97-9.18a17.194 17.194 0 0 0 7.19-13.74l1.28-21.5c.05-.87-.06-1.67-.91-1.84zM23.5 40.33a12.641 12.641 0 1 1 12.49-12.64A12.578 12.578 0 0 1 23.5 40.33zm0-23.2a10.561 10.561 0 1 0 10.43 10.56A10.17 10.17 0 0 0 23.5 17.13zm-.85 14.89a.775.775 0 0 1-.56.29h-.05a.741.741 0 0 1-.54-.23l-3.2-3.23a.809.809 0 0 1 0-1.12.785.785 0 0 1 1.1 0l2.58 1.09 5.57-5.46a.766.766 0 0 1 1.09-.11.788.788 0 0 1 .11 1.1z" fillRule="evenodd" data-color="2"></path>
    </g>
</svg>
	)
}

export default function Poterpevshiye() {

	useEffect(() => {
		setTitle('Пермский Центр Поддержки | Потерпевшие')
	}, [])

	return (
		<Main>
			<MainWrapper>


				<Strip color="#e2effe">
					<div className="frst flex-column fsz-def">
						<span>Сотрудники Центра оказывают услуги по защите прав и законных интересов лиц, пострадавших от преступлений и иных правонарушений.</span>
						<span className="mar-t-1">Способы защиты:</span>
						<UL className="defends mar-l-1_5 mar-t-1">
							<li>консультирование в области применения уголовного, административного и гражданского законодательства по отдельным категориям дел</li>
							<li>представление интересов на всех стадиях уголовного процесса</li>
							<li>направление запросов, истребование документов, подача жалоб, заявлений, ходатайств</li>
							<li>взаимодействие с органами дознания и предварительного следствия</li>
							<li>составление и подача искового заявления</li>
							<li>помощь в принятии обеспечительных мер</li>
							<li>формирование правовой позиции по делам</li>
							<li>представление интересов в суде</li>
							<li>оказание помощи в рамках исполнительного производства.</li>
						</UL>
					</div>
				</Strip>

				<Strip>
					<div className="flex-column">
						<Paragraph>Лица, признанные потерпевшими, а также их законные представители, вправе до окончания расследования и вынесения приговора суда обратиться в гражданском порядке с иском о возмещении причинённого вреда.</Paragraph>
						<Paragraph className="mar-t-2">От оперативности подачи искового заявления напрямую зависит положительный результат, направленный на обеспечение возмещения причинённого физического или имущественного ущерба.</Paragraph>
					</div>
				</Strip>

				<Strip color="#f6f6f6">
					<div className="flex-column">
						<Paragraph>Одним из направлений работы Центра в области защиты прав граждан, пострадавших от преступных действий, является информационно-консультационная работа, направленная на профилактику и предупреждение преступлений на финансовом рынке.</Paragraph>
						<div className="flex align-center shield mar-t-1">
							<Paragraph className="shield-left">Повышение финансовой грамотности населения позволяет предотвратить количество мошеннических действий и сохранить не только материальные, но и моральные блага.</Paragraph>
							<Shield />
						</div>
						<Paragraph className="fincult mar-t-1">Чтобы повысить свою финансовую культуру и не стать жертвой мошенников, перейдите на <a href="https://fincult.info/" aria-label="fincult.info">fincult.info</a></Paragraph>
					</div>
				</Strip>

				<Promo>
					Для получения более подробной информации об имеющихся способах защиты нарушенных прав граждан, пострадавших от преступных действий, свяжитесь с нами
				</Promo>

			</MainWrapper>
		</Main>
	)
}