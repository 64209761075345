import { Link } from "react-router-dom";
import styled from "styled-components";
export {forwardRef} from 'react'


const FooterWrapper = styled.footer`
	width: 100%;
	height: 8em;
	background-color: #f5f5f5;
	display: flex;
	justify-content: center;
	align-items: center;
	padding-inline: 20%;
	@media(max-width: 1450px) {
		/* font-size: .9em; */
		@media(max-width: 1200px) {
			font-size: .7em;
			@media(max-width: 875px) {
				padding-inline: 5%;
			}
		}
	}
`;

const Copyrights = styled.div`
	color: rgb(15, 76, 133);
	font-size: .8em;
`;

export default function Footer() {
	return (
		<FooterWrapper>
			<Copyrights>
				<Link to='/'>© ПЦП 2020.</Link> Представляя информацию на настоящем сайте в соответствии с требованиями статьи 9 Федерального закона от 27.07.2006 № 152-ФЗ «О персональных данных»,
				клиент подтверждает принадлежность его персональных данных лично ему и даёт согласие на их обработку.
				К персональным данным относятся адрес электронной почты, телефон, ФИО, адрес места проживания.
			</Copyrights>
		</FooterWrapper>
	)
}