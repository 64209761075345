import { createGlobalStyle, ThemeProvider } from 'styled-components';
import { BrowserRouter } from 'react-router-dom';
import ReactDOM from 'react-dom/client';

import App from './App';
import './fonts.css';


const Global = createGlobalStyle`
  * {
    margin: 0;
    padding: 0;
    box-sizing: border-box;
  }
  #root{
    height: 100%;
    /* overflow-x: auto; */
  }
  html, body, #root{
    height: 100%;
  }
  a{
    text-decoration: none;
  }
  main{
    height: 100%;
    overflow-x: hidden;
  }
  h1, h2, h3, h4, h5, h6{
    font-family: raleway;
    font-weight: normal;
  }
  button{
    cursor: pointer;
    outline: none;
  }
  input, textarea{
    &:focus-visible{
      outline: 0;
    }
  }
`;

const theme = {
  color: {
    light: '#fff',
    dark: '#555'
  }
}

const root = ReactDOM.createRoot(
  document.getElementById('root') as HTMLElement
  );
  
  export const setTitle = (text: string) => {
    document.getElementsByTagName('title')[0].innerText = text;
  }

root.render(
    <BrowserRouter>
      <ThemeProvider theme={theme}>
        <Global/>
        <App/>
      </ThemeProvider>
    </BrowserRouter>
);

