import { Route, Routes } from "react-router-dom";

import Dtp from "../pages/dtp";
import MainPage from "../pages/Main";
import Poterpevshiye from "../pages/poterpevshiye";
import Uslugi from "../pages/uslugi";


export default function AppRouter() {
	return (
		<Routes>
			<Route index element={<MainPage/>} />
			<Route path='/dtp' element={<Dtp/>} />
			<Route path='/poterpevshiye' element={<Poterpevshiye/>} />
			<Route path='/uslugi' element={<Uslugi/>} />
		</Routes>
	)
}