import styled from "styled-components"


const BlockDiv = styled.div`
	width: 100%;
	background-color: ${({style}) => style?.color};
	height: ${({style}) => style?.height};
`;

type Props = {
	height?: string;
	width?: string;
	color?: string;
	children?: any;
	className?: string;
}

export default function Block({className, children, color, height, width}: Props) {
	return (
		<BlockDiv className={className} style={{
			backgroundColor: color,
			height,
			width
		}}>
			{children}
		</BlockDiv>
	)
}